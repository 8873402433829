<template>
  <label
    for="terms"
    class="terms no-select clicable"
    :class="{'font-size-default': fontSizeBigger, 'disabled': disabled}"
  >
    <input
      type="checkbox"
      data-testid="terms"
      id="terms"
      v-model="terms"
      :disabled="disabled"
    />
    <span @click="onClick">
      {{ $t("i_accept_the") }}
    </span>
    <a 
      :href="terms_url"
      :class="{'disabled': disabled}"
      data-testid="terms-link"
      target="_blank"
    >
      {{ $t("terms_of_use") }}
    </a>
    &amp;
    <a
      :href="privacy_url"
      :class="{'disabled': disabled}"
      data-testid="policy-link"
      target="_blank"
    >
      {{ $t("privacy_policy") }}
    </a>
  </label>
</template>

<script>
export default {
  name: "Terms",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    fontSizeBigger: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      terms: false
    }
  },
  watch: {
    value: {
      handler(n) {
        this.terms = n;
      },
      immediate: true
    },
    terms(n) {
      this.$emit("input", n);
    }
  },
  computed: {
    terms_url() {
      return this.$root.config.company_terms || "#";
    },
    privacy_url() {
      return this.$root.config.company_privacy_policies || "#";
    }
  },
  methods: {
    onClick(e) {
      if (this.disabled) {
        e.stopPropagation = true;
        e.preventDefault = true;
      }
    }
  }
};
</script>

<style scoped>
label.disabled, a.disabled {
  color: gray;
}
.font-size-default {
  font-size: 13pt;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
